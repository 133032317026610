"use client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { type Session } from "next-auth"
import { useState } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { SnackbarProvider } from "shared-ui"

import SessionProvider from "@/components/session-provider"

import AlertProvider from "@/utils/useAlert"
import OldSnackbarProvider from "@/utils/useSnackbar"

interface IClientProvidersProps {
  session: Session | null
  children: React.ReactNode
}

export default function ClientProviders({
  children,
  session,
}: IClientProvidersProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  )

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <SnackbarProvider>
            <OldSnackbarProvider>
              <AlertProvider>{children}</AlertProvider>
            </OldSnackbarProvider>
          </SnackbarProvider>
        </DndProvider>
      </QueryClientProvider>
    </SessionProvider>
  )
}
